// extracted by mini-css-extract-plugin
export var bg = "Popup-module--bg--042a2";
export var block = "Popup-module--block--fd2fa";
export var close = "Popup-module--close--e9a14";
export var coupon = "Popup-module--coupon--cdf5b";
export var ctaSubtitle = "Popup-module--ctaSubtitle--0ca80";
export var ctaTitle = "Popup-module--ctaTitle--d1eea";
export var ctaWrapper = "Popup-module--ctaWrapper--7ab21";
export var discount = "Popup-module--discount--31ea2";
export var enroll = "Popup-module--enroll--39c29";
export var infoWrapper = "Popup-module--infoWrapper--0f04b";
export var mentorInfo = "Popup-module--mentorInfo--013d0";
export var mentorWrapper = "Popup-module--mentorWrapper--4ab02";
export var modalBg = "Popup-module--modalBg--7a00f";
export var name = "Popup-module--name--c2bf4";
export var position = "Popup-module--position--3f9ce";
export var title = "Popup-module--title--ed747";
export var webinar = "Popup-module--webinar--d1551";
export var wrapper = "Popup-module--wrapper--8407f";