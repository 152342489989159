// extracted by mini-css-extract-plugin
export var active = "blog-post-module--active--9008a";
export var bg = "blog-post-module--bg--8718d";
export var category = "blog-post-module--category--2695f";
export var date = "blog-post-module--date--27fae";
export var info = "blog-post-module--info--c18b2";
export var label = "blog-post-module--label--344d0";
export var menuWrapper = "blog-post-module--menuWrapper--7b2c6";
export var postAuthor = "blog-post-module--postAuthor--b07f0";
export var postAuthorImg = "blog-post-module--postAuthorImg--9614f";
export var postAuthorName = "blog-post-module--postAuthorName--0121f";
export var postContent = "blog-post-module--postContent--ae53d";
export var postContentMenu = "blog-post-module--postContentMenu--8cb25";
export var postHeading = "blog-post-module--postHeading--33126";
export var postItem = "blog-post-module--postItem--b6c27";
export var readingTime = "blog-post-module--readingTime--13c98";
export var shareBlock = "blog-post-module--shareBlock--bd351";
export var shareSectionWrapper = "blog-post-module--shareSectionWrapper--fec31";
export var sidebarAuthorDescription = "blog-post-module--sidebarAuthorDescription--3582b";
export var sidebarAuthorImg = "blog-post-module--sidebarAuthorImg--c0674";
export var sidebarAuthorImgWrapper = "blog-post-module--sidebarAuthorImgWrapper--df7ac";
export var sidebarAuthorName = "blog-post-module--sidebarAuthorName--cfa43";
export var sidebarAuthorWrapper = "blog-post-module--sidebarAuthorWrapper--1017f";
export var sidebarStickyPart = "blog-post-module--sidebarStickyPart--a0c7c";
export var socials = "blog-post-module--socials--8f6e2";
export var title = "blog-post-module--title--9d5c2";